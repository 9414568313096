import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Controller, useForm } from 'react-hook-form';
import { TablePagination, Modal } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AttachFile,
  Edit,
  Pageview,
  Search,
  TrendingUpRounded,
  Visibility
} from '@material-ui/icons';

import CropFreeIcon from '@material-ui/icons/CropFree';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import LogoImage from '../../assets/images/logo.png';
import Table from '../../components/Table';
import { get, put } from '../../shared/api';
import { ORDER_STATUSES, ROLE_ADMIN, ROLE_WAREHOUSE } from '../../shared/contants';
import { getErrorMessage } from '../../shared/helpers';
import { selectUser } from '../../store/selectors/auth.selectors';

import styles from './Orders.module.scss';
// import noImage from '../../assets/images/no-image.jpeg';
import TransferList from '../../components/TransferList';
import QRCode from 'qrcode.react';

const loadPDF = (order) => new Promise((resolve, reject) => {
  get(`/pdf/get/${order.id}`)
    .then((files) => {
      let attachments = {};

      if (files) {
        if (Array.isArray(files)) {
          attachments = files.filter(file => !!file);
        } else {
          attachments = files;
        }
      }

      order.attachments = attachments;

      resolve(order);
    })
    .catch((error) => {
      reject(error);
    });
});

const Orders = () => {
  const [pageNumber, setPage] = useState(0);
  const [pageSize, setRowsPerPage] = useState(20);
  const [total, setTotal] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [tarckingOrders, setTrackingOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState();
  const [statusCounters, setStatusCounters] = useState({});
  const [isUpdateStatusesDialogOpen, setIsUpdateStatusesDialogOpen] = useState(false);
  const [isUpdateTrackingDialogOpen, setIsUpdateTrackingDialogOpen] = useState(false);
  const [vehiclesToUpdate, setVehiclesToUpdate] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const currentUser = useSelector(selectUser);
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();

  const [qrCodeContent, setQRCodeContent] = useState();
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [showQRCodeMap, setShowQRCodeMap] = useState({});
  const [currentQRCode, setCurrentQRCode] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalVehicleInfo, setModalVehicleInfo] = useState({
    vin: '',
    make: '',
    model: '',
    destination: '',
    origin: '',
  });

  const {
    control: updateControl,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdateStatuses,
  } = useForm();

  const setFilteredOrders = (status, searchTerm) => {
    // if (status === 'work') {
    //   filteredOrders = filteredOrders.filter((order) => ['New', 'Dispatched', 'AtTerminal'].includes(order?.status));
    // } else if (status && status !== 'all') {
    //   filteredOrders = filteredOrders.filter((order) => order?.status === status);
    // }

    if (searchTerm && searchTerm.trim()) {
      setSearchFilter(searchTerm);
    }
    loadOrders(status, pageNumber, pageSize);
  };

  const loadOrders = (type, pageNumber, pageSize) => {
    setIsLoading(true);
    let queryParams = [];

    if (type) {
      switch (type) {
        case 'all': {
          queryParams = ORDER_STATUSES.map(status => status.value);
          break;
        }
        case 'work': {
          queryParams = ORDER_STATUSES
            .filter(status => ['New', 'Dispatched', 'AtTerminal'].includes(status.key))
            .map(status => status.value);
          break;
        }
        default: {
          const orderStatus = ORDER_STATUSES.find(status => status.key === type);
          queryParams = orderStatus ? [orderStatus.value] : [];
        }
      }
    } else if (currentUser?.role === ROLE_ADMIN) {
      queryParams = ORDER_STATUSES
        .filter(status => ['New', 'Dispatched', 'AtTerminal'].includes(status.key))
        .map(status => status.value);
    }

    let ordersUrl = `/vehicles/my${queryParams.length ? `?${queryParams.map(param => `status=${param}`).join('&')}&pageNumber=${pageNumber}&pageSize=${pageSize}` : ''}`;
    if (currentUser.role === ROLE_ADMIN) {
      ordersUrl = `/vehicles${queryParams.length ? `?${queryParams.map(param => `status=${param}`).join('&')}&pageNumber=${pageNumber}&pageSize=${pageSize}` : ''}`;
    } else if (currentUser.role === ROLE_WAREHOUSE) {
      ordersUrl = `/vehicles/warehouse/${currentUser.email}`;
    }

    if (searchFilter !== '') {
      ordersUrl += `&search=${searchFilter}`
    }

    get(ordersUrl)
      .then(async (ordersData) => {
        await Promise.all(ordersData.vehicleData.map(async (order) => await loadPDF(order)));

        setOrders(ordersData.vehicleData);
        setTotal(ordersData.total);

        if (type) {
          setStatusFilter(type)
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });

    if (currentUser && currentUser.role && currentUser.email) {
      loadStatusCounters(currentUser.email);
    }
  };

  const loadOrdersForBulkUpdate = (type) => {
    let queryParams = [];

    if (type) {
      switch (type) {
        case 'all': {
          queryParams = ORDER_STATUSES.map(status => status.value);
          break;
        }
        case 'work': {
          queryParams = ORDER_STATUSES
            .filter(status => ['New', 'Dispatched', 'AtTerminal'].includes(status.key))
            .map(status => status.value);
          break;
        }
        default: {
          const orderStatus = ORDER_STATUSES.find(status => status.key === type);
          queryParams = orderStatus ? [orderStatus.value] : [];
        }
      }
    } else if (currentUser?.role === ROLE_ADMIN) {
      queryParams = ORDER_STATUSES
        .filter(status => ['New', 'Dispatched', 'AtTerminal'].includes(status.key))
        .map(status => status.value);
    }

    if (currentUser.role === ROLE_ADMIN) {
      let ordersUrl = `/vehicles/bulk${queryParams.length ? `?${queryParams.map(param => `status=${param}`).join('&')}&pageNumber=${pageNumber}&pageSize=${pageSize}` : ''}`;

      get(ordersUrl)
        .then(async (ordersData) => {
          setAllOrders(ordersData);
        })
        .catch((error) => {
          addToast(getErrorMessage(error), { appearance: 'error' });
        })
    }
  };

  const loadOrdersForTrackingUpdate = (type) => {
    let queryParams = [];

    if (type) {
      switch (type) {
        case 'all': {
          queryParams = ORDER_STATUSES.map(status => status.value);
          break;
        }
        case 'work': {
          queryParams = ORDER_STATUSES
            .filter(status => ['New', 'Dispatched', 'AtTerminal'].includes(status.key))
            .map(status => status.value);
          break;
        }
        default: {
          const orderStatus = ORDER_STATUSES.find(status => status.key === type);
          queryParams = orderStatus ? [orderStatus.value] : [];
        }
      }
    } else if (currentUser?.role === ROLE_ADMIN) {
      queryParams = ORDER_STATUSES
        .filter(status => ['New', 'Dispatched', 'AtTerminal'].includes(status.key))
        .map(status => status.value);
    }

    if (currentUser.role === ROLE_ADMIN) {
      let ordersUrl = `/vehicles/title${queryParams.length ? `?${queryParams.map(param => `status=${param}`).join('&')}` : ''}`;

      get(ordersUrl)
        .then(async (ordersData) => {
          setTrackingOrders(ordersData);
        })
        .catch((error) => {
          addToast(getErrorMessage(error), { appearance: 'error' });
        })
    }
  };

  const loadStatusCounters = (userEmail) => {
    get(`/vehicles/counters${currentUser.role === ROLE_ADMIN ? '' : `?email=${userEmail}`}`)
      .then((counts) => {
        let statuses = { ...counts };
        const workStatuses = ['New', 'Dispatched', 'AtTerminal'];

        const counters = {
          ...statuses,
          all: Object.values(statuses).reduce((acc, value) => acc + value, 0),
          work: workStatuses.reduce((acc, statusKey) => acc + (statuses[statusKey] || 0), 0),
        };

        setStatusCounters(counters);
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  // const filterOrders = (statusKey) => {
  //   if (currentUser?.role === ROLE_ADMIN) {
  //     loadOrders(statusKey);
  //   } else {
  //     setStatusFilter(statusKey)
  //   }
  // };

  useEffect(() => {
    loadOrders(statusFilter, pageNumber, pageSize);
    loadOrdersForBulkUpdate(statusFilter);
    loadOrdersForTrackingUpdate(statusFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredOrders(statusFilter, searchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, searchFilter, pageNumber, pageSize]);

  const loadFiles = (vehicleId) => {
    const vehicleIndex = orders.findIndex(vehicle => vehicle.id === vehicleId);

    if (vehicleIndex < 0) {
      addToast('Vehicle was not found!', { appearance: 'error' });
      return;
    }

    const vehicleData = { ...orders[vehicleIndex] };
    vehicleData.areFilesLoading = true;
    const oldOrders = [...orders];
    oldOrders[vehicleIndex] = vehicleData;
    setOrders(oldOrders);

    get(`/pdf/get/${vehicleData.id}`)
      .then((files) => {
        if (files && Array.isArray(files)) {
          vehicleData.attachments = files.filter(file => !!file);
        } else {
          vehicleData.attachments = files;
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        vehicleData.areFilesLoading = false;

        const oldOrders = [...orders];
        oldOrders[vehicleIndex] = vehicleData;
        setOrders(oldOrders);
      });
  };

  const searchVehicles = ({ search }) => {
    setSearchFilter(search.trim());
  };

  const openUpdateStatusesDialog = (event) => {
    event.preventDefault();

    setIsUpdateStatusesDialogOpen(true);
  };

  const closeUpdateStatusesDialog = () => {
    setIsUpdateStatusesDialogOpen(false);
    resetUpdateStatuses({ status: '' });
    setVehiclesToUpdate([]);
  };

  const updateVehicleStatuses = async (data) => {
    setIsLoading(true);

    const { status } = data;

    const formData = {
      status: status,
      vinNumbers: vehiclesToUpdate,
    };

    await put(`/vehicles/status/bulk`, formData)
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast(
            'Vehicle statuses updated successfully',
            { appearance: 'success' }
          );

          closeUpdateStatusesDialog();
          reset({ search: '' });
          setSearchFilter('');
          loadOrders(statusFilter, pageNumber, pageSize);
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openUpdateTrackingDialog = (event) => {
    event.preventDefault();

    setIsUpdateTrackingDialogOpen(true);
  };

  const closeUpdateTrackingDialog = () => {
    setIsUpdateTrackingDialogOpen(false);
    resetUpdateStatuses({ status: '' });
    setVehiclesToUpdate([]);
  };

  const updateVehicleTrackingNumber = async (data) => {
    setIsLoading(true);

    const { company, trackingNumber } = data;

    const formData = {
      trackingNumber: company + trackingNumber,
      vinNumbers: vehiclesToUpdate,
    };

    await put(`/vehicles/title/bulk`, formData)
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast(
            'Vehicle statuses updated successfully',
            { appearance: 'success' }
          );

          closeUpdateTrackingDialog();
          reset({ search: '' });
          setSearchFilter('');
          loadOrders(statusFilter, pageNumber, pageSize);
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadOrders(statusFilter, newPage, pageSize)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleFiltersAndOrders(statusValue) {
    setPage(0)
    setStatusFilter(statusValue);
    loadOrders(statusValue, pageNumber, pageSize);
    loadOrdersForBulkUpdate(statusValue);
    loadOrdersForTrackingUpdate(statusValue);
  }

  //QR Generation Code
  const generateQRCode = (order) => {
    setSelectedOrderId(order.id);
    setQRCodeContent(`${order.vin},${order.placeOfDelivery},${order.make} ${order.model}`);
    setModalVehicleInfo({
      vin: order.vin,
      make: order.make,
      model: order.model,
      destination: order.placeOfDelivery,
      origin: order.auctionLocation?.name
    });
    setModalOpen(true); // Open the modal
  };


  const downloadQR = (orderVin) => {
    const canvas = document.getElementById("qr-code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qr-${orderVin}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const toggleQRCodeVisibility = (orderId) => {
    setShowQRCodeMap((prevShowQRCodeMap) => ({
      ...prevShowQRCodeMap,
      [orderId]: !prevShowQRCodeMap[orderId],
    }));
  };

  const handleQRCodeButtonClick = (order) => {
    toggleQRCodeVisibility(order.id);
    generateQRCode(order);
    setCurrentQRCode((prevQRCode) => (prevQRCode === order.id ? null : order.id));
  };

  const handlePrint = () => {
    // Hide elements you don't want to print
    window.print();
  };


  return (
    <>
      <Helmet>
        <title>Vehicles | {MAIN_TITLE}</title>
      </Helmet>
      {isLoading && <Loader />}
      <TableHead>
        <div style={{ padding: '10px' }}>
          <TablePagination
            component="div"
            style={{
              backgroundColor: '#ffff', color: '#3f51b5', borderRadius: '10px',
            }}
            abelRowsPerPage={<InputLabel>Rows per page:</InputLabel>}
            rowsPerPageOptions={[10, 20, 30, 50]}
            rowsPerPage={pageSize}
            count={total}
            page={pageNumber}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableHead>
      <Table
        columns={[
          {
            title: 'Status',
            field: 'status',
            searchable: false,
            render: order => (
              <Chip
                label={ORDER_STATUSES.find((status) => status.key === order?.status)?.label || order.status || '-'}
                color="primary"
              />
            )
          },
          (currentUser.role === ROLE_ADMIN ? {
            title: 'User',
            field: 'firstLastName',
            render: order => (
              <div>
                <p>
                  {order.firstLastName ? (
                    <a href={`mailto:${order.userName}`}>{order.firstLastName}</a>) : 'Not specified'}
                </p>
                <br />
              </div>
            )
          } : null),
          {
            title: 'Vehicle', field: 'year', render: order => (
              <>
                {order.year}&nbsp;{order.make}&nbsp;{order.model}
                <br />
                <br />
                {
                  order.vin ? (
                    <Link
                      to={`/orders/${order.id}`}
                      component={RouterLink}
                      target="_blank"
                      color="primary"
                      area-label="view"
                    >
                      {order.vin}
                    </Link>
                  ) : '-'
                }
              </>
            )
          },
          {
            title: 'Origin', field: 'auctionName.name', render: order => (
              <div>
                {order.auctionName?.name && (
                  <p>{order.auctionName.name}</p>
                )}
                <br />
                {order.auctionLocation?.name && (
                  <p>/{order.auctionLocation.name}/</p>
                )}
              </div>
            )
          },
          {
            title: 'Created', field: 'createdDate', render: order => order.createdDate,
          },
          {
            title: 'Destination', field: 'expectedDate', render: order => (
              <div>
                <p>
                  <strong style={{ fontWeight: 600 }}>POD:&nbsp;</strong>
                  {order.placeOfDelivery || '-'}
                </p>
                <br />
                <p>
                  <strong style={{ fontWeight: 600 }}>
                    ETA:&nbsp;
                  </strong>
                  {order.expectedDate || order.container?.expectedDate || 'N/A'}
                </p>
              </div>
            )
          },
          {
            title: 'Documents',
            field: 'documents',
            sorting: false,
            searchable: false,
            render: order => order.attachments ? (
              <div style={{ minWidth: '130px' }}>
                {Array.isArray(order.attachments) ? (
                  <div>
                    Files:&nbsp;{order.attachments.length ? order.attachments.map((fileData, index) => (
                      <IconButton
                        key={`file-${index}`}
                        component="a"
                        href={fileData?.url || fileData}
                        rel="noopener noreferrer"
                        target="_blank"
                        color="primary"
                        area-label="download"
                        size="small"
                        title={fileData.title || fileData}
                      >
                        <AttachFile fontSize="small" />
                      </IconButton>
                    )) : (
                      <Typography color="error" variant="body1">
                        N/A
                      </Typography>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className={styles.documentRow}>
                      <span className={styles.documentTitle}>Bill of lading:&nbsp;</span>
                      {order.attachments?.billOfLading?.length
                        ? order.attachments.billOfLading.map((fileData, index) => (
                          <IconButton
                            key={`bol-${index}`}
                            component="a"
                            href={fileData?.url || fileData}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={fileData.title || fileData}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        ))
                        : (
                          <Typography color="error" variant="body1">
                            N/A
                          </Typography>
                        )
                      }
                    </div>
                    <div className={styles.documentRow}>
                      <span className={styles.documentTitle}>Bill of sales:&nbsp;</span>
                      {order.attachments?.billOfSale?.length
                        ? order.attachments.billOfSale.map((fileData, index) => (
                          <IconButton
                            key={`bos-${index}`}
                            component="a"
                            href={fileData?.url || fileData}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={fileData.title || fileData}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        ))
                        : (
                          <Typography color="error" variant="body1">
                            N/A
                          </Typography>
                        )
                      }
                    </div>
                    <div className={styles.documentRow}>
                      <span className={styles.documentTitle}>Vehicle title:&nbsp;</span>
                      {order.attachments?.vehicleTitles?.length
                        ? order.attachments.vehicleTitles.map((fileData, index) => (
                          <IconButton
                            key={`vt-${index}`}
                            component="a"
                            href={fileData?.url || fileData}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={fileData.title || fileData}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        ))
                        : (
                          <Typography color="error" variant="body1">
                            N/A
                          </Typography>
                        )
                      }
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ minWidth: '130px' }}>
                <Button
                  component="button"
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={order.areFilesLoading}
                  onClick={() => loadFiles(order.id)}
                  style={{ marginRight: '5px' }}
                >
                  <Pageview />&nbsp;Load files
                </Button>
              </div>
            )
          },
          {
            searchable: false,
            sorting: false,
            render: order => (
              <>
                <IconButton
                  to={`/orders/${order.id}`}
                  component={RouterLink}
                  target = "_blank"
                  color="primary"
                  aria-label="view"
                >
                  <Visibility />
                </IconButton>

                {currentUser && (currentUser.role === ROLE_ADMIN || currentUser.role === ROLE_WAREHOUSE) && (
                  <div>
                    <IconButton
                      to={`/orders/${order.id}/edit`}
                      component={RouterLink}
                      color="primary"
                      aria-label="edit"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleQRCodeButtonClick(order)}
                      color="primary"
                      aria-label="generate-qr-code"
                    >
                      <CropFreeIcon />
                    </IconButton>
                  </div>
                )}
                {(showQRCodeMap[order.id] || currentQRCode === order.id) && qrCodeContent && selectedOrderId === order.id && (
                  <Modal id="modal" className={styles.modalContainer} open={modalOpen} onClose={() => setModalOpen(false)}>
                    <div className={styles.modalContent}>
                      <div id="modal-content" className={styles.contentWrapper}>
                        <div className={styles.qrCodeInfo}>
                          <QRCode
                            id="qr-code"
                            value={qrCodeContent}
                            size={200}
                            level={"H"}
                            includeMargin={true}
                            imageSettings={{
                              src: LogoImage,
                              height: 30,
                              width: 30,
                              excavate: true,
                            }}
                          />
                          <div className={styles.infoContainer}>
                            <p><span className={styles.destination}>{modalVehicleInfo.destination.substring(0,6)}</span></p>
                            <p>{modalVehicleInfo.vin}</p>
                            <p>{modalVehicleInfo.make} {modalVehicleInfo.model}</p>
                            <p>{modalVehicleInfo.origin.toLocaleUpperCase()}</p>
                            <div className={styles.checkboxContainer}>
                            <input type="checkbox" /> &nbsp;&nbsp;<label>Keys</label>
                            <input type="checkbox" /> &nbsp;&nbsp;<label>Photo</label>
                            <input type="checkbox" /> &nbsp;&nbsp;<label>Start</label>
                            <input type="checkbox" /> &nbsp;&nbsp;<label>Boost</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.modalButtonContainer} non-printable`}>
                        <Button id="non-printable" variant="contained" color="primary" onClick={() => handlePrint()}>
                          Print
                        </Button>
                        <Button id="non-printable" variant="contained" color="default" onClick={() => setModalOpen(false)}>
                          Close
                        </Button>
                      </div>
                    </div>
                  </Modal>
                )}
              </>
            )
          }
        ].filter((column) => !!column)}
        data={orders}
        options={
          {
            search: false,
            paging: false,
            maxBodyHeight: 'calc(100vh - 225px)',
          }
        }
        components={{
          Toolbar: props => (
            <div style={{ padding: '10px 15px' }}>
              <Grid container alignItems="center">
                <Grid item xs={6} lg={5}>
                  <Typography variant="h5">
                    Vehicles
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                  <Grid container justify="flex-end">
                    {(
                      currentUser &&
                      (currentUser.role === ROLE_ADMIN
                        || currentUser.role === ROLE_WAREHOUSE
                      )) && (
                        <div>
                          <Button
                            style={{ marginRight: '10px' }}
                            onClick={openUpdateStatusesDialog}
                            color="primary"
                            variant="outlined"
                          >
                            Update statuses
                          </Button>
                          <Button
                            style={{ marginRight: '10px' }}
                            onClick={openUpdateTrackingDialog}
                            color="primary"
                            variant="outlined"
                          >
                            Update Tracking
                          </Button>
                        </div>
                      )}
                    <form onSubmit={handleSubmit(searchVehicles)}>
                      <Controller
                        name="search"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={styles.searchField}
                            label="Search"
                            variant="outlined"
                            placeholder="Search by model, make, year, etc..."
                            autoComplete="email"
                            required={false}
                            size="small"
                          />
                        )}
                      />
                      <IconButton color="primary" size="small" style={{ margin: '5px 0 0 8px' }} type="submit">
                        <Search />
                      </IconButton>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <ButtonGroup disableElevation variant="contained" fullWidth>
                {(
                  currentUser &&
                  currentUser.role === ROLE_ADMIN) ? (
                  <Button
                    style={{
                      color: statusFilter === 'work' ? '#ffffff' : null,
                      backgroundColor: statusFilter === 'work' ? '#3f51b5' : null
                    }}
                    onClick={() => handleFiltersAndOrders('work')}
                  >
                    All in Work ({statusCounters['work'] ?? '-'})
                  </Button>
                ) : null}
                {ORDER_STATUSES.map(({ key, label }) => (
                  <Button
                    key={`status-${key}`}
                    style={{
                      color: statusFilter === key ? '#ffffff' : null,
                      backgroundColor: statusFilter === key ? '#3f51b5' : null
                    }}
                    onClick={() => handleFiltersAndOrders(key)}
                  >
                    {label}  ({statusCounters[key] ?? '-'})
                  </Button>
                ))}
                <Button
                  style={{
                    color: statusFilter === 'all' ? '#ffffff' : null,
                    backgroundColor: statusFilter === 'all' ? '#3f51b5' : null
                  }}
                  onClick={() => handleFiltersAndOrders('all')}
                >
                  All ({statusCounters['all'] ?? '-'})
                </Button>
              </ButtonGroup>
            </div>
          )
        }}
      />
      <Dialog
        open={isUpdateStatusesDialogOpen}
        onClose={closeUpdateStatusesDialog}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={handleUpdateSubmit(updateVehicleStatuses)}>
          <DialogTitle>Update multiple vehicles statuses</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="status" variant="outlined" required>New status</InputLabel>
                  <Controller
                    name="status"
                    control={updateControl}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="status"
                        label="New status"
                        variant="outlined"
                        required
                        fullWidth
                      >
                        {ORDER_STATUSES.map(({ key, label, value }) => (
                          <MenuItem key={`status-${key}`} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TransferList
                  leftLabel="Vehicles"
                  rightLabel="Vehicles to update"
                  initialLeftItems={allOrders.map(v => v.vin)}
                  initialRightItems={[]}
                  handleChanged={(vinNumbers) => setVehiclesToUpdate(vinNumbers)}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              disabled={vehiclesToUpdate.length === 0}
            >
              Save
            </Button>
            <Button onClick={closeUpdateStatusesDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isUpdateTrackingDialogOpen}
        onClose={closeUpdateTrackingDialog}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={handleUpdateSubmit(updateVehicleTrackingNumber)}>
          <DialogTitle>Update multiple vehicles tracking number</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="company" variant="outlined" required>Company</InputLabel>
                  <Controller
                    name="company"
                    control={updateControl}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="company"
                        label="Company"
                        variant="outlined"
                        required
                        fullWidth
                      >
                        <MenuItem key="DHL" value="https://www.dhl.com/global-en/home/tracking.html?locale=true,true&tracking-id=">
                          DHL
                        </MenuItem>
                        <MenuItem key="FedEx" value="https://www.fedex.com/fedextrack/">
                          FedEx
                        </MenuItem>
                        <MenuItem key="UPS" value="https://www.ups.com/track">
                          UPS
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <Controller
                    name="trackingNumber"
                    control={updateControl}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={styles.searchField}
                        variant="outlined"
                        label="Tracking number"
                        required
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TransferList
                  leftLabel="Vehicles"
                  rightLabel="Vehicles to update"
                  initialLeftItems={tarckingOrders.map(v => v.vin)}
                  initialRightItems={[]}
                  handleChanged={(vinNumbers) => setVehiclesToUpdate(vinNumbers)}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              disabled={vehiclesToUpdate.length === 0}
            >
              Save
            </Button>
            <Button onClick={closeUpdateTrackingDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Orders;
